<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap">
      <img class="banner__img thumbnails__img" src="../assets/img/banners/success-banner.jpg" alt="">
    </div>
    <div class="thumbnails__textWrap">
      <p class="titlePage titlePage_subtitle text_green">your application has been send to</p><br>
      <h1 class="titlePage">starline <br><span class="text_green">security</span></h1>
      <p class="text">We will review your application and get in touch as soon as your
        application assessment has been completed.</p>
      <p class="text">The application assessment process takes approximately 2-3 working days. You can call 07 3272 2974 during business hours to fast track your new dealer application. </p>
      <p class="text">To check your application status
        <router-link to="status" class="text_green">use the link provided</router-link>
        in confirmation email we just sent you.
      </p>
      <p class="text">
        If you don't receive email confirmation within 30 minutes please check your spam folder.
      </p>
      <p class="text text_italic text_green">Thank you for applying.</p>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "Success"
  }
</script>

<style scoped lang="scss">
  @import "../assets/scss/utils/vars";

  .titlePage {
    font-size: 5rem;
    margin: 0 0 3%;
    &_subtitle {
      font-size: 1.5rem;
      text-align: center;
      margin: 0;
    }
  }

  @media screen and (max-width: $sm) {
    .titlePage {
      font-size: 3rem;
      &_subtitle {
        font-size: $h4;
        margin: 3% 0;
      }
    }
  }

</style>
